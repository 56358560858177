import React from 'react';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';

import Hero from 'components/hero';
import DefaultLayout from 'layouts/default';

const title = 'O nas';

const AboutPage = ({ data }) => (
  <DefaultLayout
    metaDescription="Dwójka programistów mieszkających w Berlinie i remontujących dom z 1936 w województwie lubuskim."
    title={title}
    url="/o-nas"
  >
    <Hero image={data.night.childImageSharp.fluid} title={title} />
    <main>
      <article className="article">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Purus ut
          faucibus pulvinar elementum integer. Faucibus pulvinar elementum
          integer enim neque. At consectetur lorem donec massa sapien faucibus
          et molestie ac. Sagittis nisl rhoncus mattis rhoncus urna neque
          viverra. Quam elementum pulvinar etiam non quam. Ipsum suspendisse
          ultrices gravida dictum fusce ut placerat orci. Feugiat nisl pretium
          fusce id velit ut. Maecenas ultricies mi eget mauris. Sit amet
          consectetur adipiscing elit. Lectus urna duis convallis convallis
          tellus id interdum. Risus pretium quam vulputate dignissim suspendisse
          in est ante.
        </p>
        <Img
          className="u-image"
          fluid={data.house.childImageSharp.fluid}
          alt="House"
        />
        <Img
          className="u-image"
          fluid={data.night.childImageSharp.fluid}
          alt="Night stars"
        />
        <p>
          Commodo ullamcorper a lacus vestibulum sed arcu non odio. Porttitor
          rhoncus dolor purus non enim praesent elementum. Eu volutpat odio
          facilisis mauris sit amet. Maecenas pharetra convallis posuere morbi
          leo urna molestie at. Risus quis varius quam quisque. Ut porttitor leo
          a diam sollicitudin tempor id. Odio pellentesque diam volutpat commodo
          sed. Sit amet consectetur adipiscing elit ut. A condimentum vitae
          sapien pellentesque habitant morbi tristique senectus. Quis enim
          lobortis scelerisque fermentum dui faucibus in. At ultrices mi tempus
          imperdiet nulla malesuada pellentesque. Adipiscing at in tellus
          integer feugiat scelerisque varius. Magnis dis parturient montes
          nascetur. Cras adipiscing enim eu turpis egestas pretium aenean
          pharetra magna. Hac habitasse platea dictumst quisque sagittis purus
          sit. Senectus et netus et malesuada fames ac turpis. Netus et
          malesuada fames ac turpis egestas integer. Adipiscing elit ut aliquam
          purus sit amet luctus venenatis. Sed tempus urna et pharetra pharetra
          massa massa ultricies mi.
        </p>
        <p>
          Lorem donec massa sapien faucibus et molestie ac. Sodales neque
          sodales ut etiam sit amet nisl purus. Est lorem ipsum dolor sit amet.
          Ac turpis egestas maecenas pharetra convallis posuere. Pretium aenean
          pharetra magna ac placerat. Commodo quis imperdiet massa tincidunt
          nunc pulvinar. Id velit ut tortor pretium viverra suspendisse potenti
          nullam. Quam viverra orci sagittis eu volutpat odio. Ornare arcu dui
          vivamus arcu. Faucibus nisl tincidunt eget nullam non. Quisque non
          tellus orci ac. Facilisis volutpat est velit egestas. Elementum nibh
          tellus molestie nunc non. In ante metus dictum at tempor commodo
          ullamcorper. Risus sed vulputate odio ut enim blandit volutpat. Orci
          porta non pulvinar neque. Sapien pellentesque habitant morbi tristique
          senectus et. Aliquet eget sit amet tellus.
        </p>
      </article>
    </main>
  </DefaultLayout>
);

export default AboutPage;

export const pageQuery = graphql`
  query AboutQuery {
    house: file(relativePath: { eq: "house.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    night: file(relativePath: { eq: "night-stars.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
